/* Scope all styles to the SignUp component */
.sign-up-form {
    /* All existing styles here */
}

.sign-up-form .custom-input {
    color: #E2E2E2 !important;
    background-color: #121212 !important;
    border-color: #009090 !important;
}

.sign-up-form .custom-input:-webkit-autofill,
.sign-up-form .custom-input:-webkit-autofill:hover, 
.sign-up-form .custom-input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #121212 inset !important;
    -webkit-text-fill-color: #E2E2E2 !important;
    caret-color: #E2E2E2 !important;
}

.sign-up-form .ant-form-item-label > label {
    color: #E2E2E2 !important;
}

.sign-up-form .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.sign-up-form .logo {
    max-height: 80px;
    width: auto;
    max-width: 100%;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #009090 !important; /* Use !important to override default Ant Design styles */
    background-color: #009090 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009090 !important;
    border-color: #009090 !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
    box-shadow: 0 0 0 2px rgba(0, 144, 144, 0.2); /* Apply green shadow instead of blue */
}

.ant-btn-link {
    color: #009090 !important;
    font-size: 15px;
}

.checkbox-label {
    color: #E2E2E2; /* White */
    font-size: 15px;
}

@media (max-width: 576px) {
    .sign-up-form .logo {
        max-height: 60px;
    }

    .sign-up-form .ant-form-item {
        margin-bottom: 16px;
    }

    .sign-up-form .ant-form {
        padding: 15px;
    }

    .sign-up-form .form-container {
        padding: 10px;
    }

    .sign-up-form .form-wrapper {
        padding: 10px;
    }

    .sign-up-form body {
        background-color: #121212;
        color: #E2E2E2;
        margin: 0;
        padding: 0;
    }

    .sign-up-form .ant-row {
        margin: 0 !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .sign-up-form .form-container {
        padding: 30px;
        max-width: 600px;
    }

    .sign-up-form .ant-form {
        padding: 30px;
    }
}

@media (min-width: 1200px) {
    .sign-up-form .form-container {
        padding: 40px;
        max-width: 700px;
    }

    .sign-up-form .ant-form {
        padding: 40px;
    }
}
