.App {
  text-align: center;
}
.formStyle {
  align-content: center;
}
.ant-table table { 
  font-size: 13px;
  width: 100%; 
}
.registerContainer {
  width: 100%;
  text-align: center;
  align-items: center;
  /* margin-left: 330px; */
  /* margin-top: 50px; */
}
.login-form {
  max-width: 500px;
}
.login-form-forgot {
  /* float: right; */
}
.login-form-button {
  width: 100%;
}
.loginContainer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.tabs {
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
}
.tabCard {
  padding-bottom: 200px;
  position: relative;
}
.logoutButton {
  position: absolute;
  top: 32px;
  right: 50px;
  float: right;
  z-index: 5;
}
.calendarDropdown {
  margin-bottom: 20px;
}
.ant-table-footer {
  text-align: left;
}
.isUpdated {
  background-color: #e6f7ff; /* Light blue background */
}