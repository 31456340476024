body {
  background-color: #121212;
  /* Set dark background color for the entire page */
  margin: 0;
  /* Remove default body margin */
  padding: 0;
  /* Remove default body padding */
}

.registration-success-container {
  color: #e5e5e5;
  /* Set text color */
  padding: 40px;
  /* Increase padding to add space */
  text-align: center;
}

.registration-success-container .logo {
  max-width: 100%;
  /* Set maximum width to ensure responsiveness */
  height: auto;
  margin-bottom: 40px;
  /* Add margin to separate from other elements */
}

.confirmation-heading {
  color: #E2E2E2;
  margin-bottom: 30px;
  /* Add margin to separate from logo */
}

.confirmation-message {
  margin-bottom: 30px;
  /* Add margin to separate from app download links */
}

.app-download-links a {
  display: inline-block;
  margin: 20px;
  /* Add margin between links */
  padding: 15px 30px;
  /* Increase padding for better touch target */
  border-radius: 5px;
  background-color: #232021;
  /* Dark background color */
  color: #e5e5e5;
  /* Text color */
  text-decoration: none;
  border: .5px solid transparent;
  /* Add transparent border initially */
  transition: border-color 0.3s;
  /* Add smooth transition for border color change */
}

.app-download-links a:hover {
  background-color: #009090;
  border-color: #006565;
  /* Darken the border color on hover */
}

.app-download-links a:focus,
.app-download-links a:not(:hover) {
  border-color: #009090;
  /* Set border color to icon color when not hovered or focused */
}

/* Media query for tablet sizes */
@media (min-width: 768px) and (max-width: 1024px) {
  .registration-success-container .logo {
    max-width: 40%;
    /* Adjust max-width for tablet sizes */
  }
}

/* Media query for desktop sizes */
@media (min-width: 1025px) {
  .registration-success-container .logo {
    max-width: 30%; /* Adjust max-width for desktop sizes */
  }
}